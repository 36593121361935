import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Router, ActivationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/core/services/request-manager/request-manager.service';

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {
  constructor(
    router: Router,
    private helperService: HelperService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.helperService.cancelPendingRequests();
      }
    });
  }

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    return next
      .handle(req)
      .pipe(takeUntil(this.helperService.onCancelPendingRequests()));
  }
}
