<div class="vertical-center">
  <a [routerLink]="['/home/dashboard']">
    <img src="/assets/toray-logo.png" alt="Toray Logo" class="align-logo" />
  </a>
  <img src="/assets/divider.svg" alt="vector" class="mr-5" />
  <div class="heading-icons-layout">
    <app-typography
      [content]="navbarTitle"
      size="lg-xl"
      tag="p"
      color="white"
      [strong]="true"
    ></app-typography>
    <div class="display-flex" *ngIf="isLoggedIn">
      <i class="fa-solid fa-house fa-lg mr-5" style="padding-top: 14px; font-size: 20px; cursor: pointer;" (click)="backToOracleSystem()" title="Return To Oracle ERP"></i>
      <img src="/assets/profile.svg" alt="Profile pic" class="mr-5" />
      <app-typography
        title=""
        [content]="userName"
        size="lg"
        tag="p"
        color="white"
      ></app-typography>
    </div>
  </div>
</div>
