<ng-container [ngSwitch]="tag">
  <p *ngSwitchCase="'p'" [ngClass]="classes" [title]="title">
    {{ content }}
  </p>
  <span *ngSwitchCase="'span'" [ngClass]="classes" [title]="title">
    {{ content }}
  </span>
  <div *ngSwitchCase="'div'" [ngClass]="classes" [title]="title">
    {{ content }}
  </div>
  <a
    *ngSwitchCase="'a'"
    [ngClass]="classes"
    [href]="href"
    [target]="target"
    [title]="title"
  >
    {{ content }}
  </a>
  <li *ngSwitchCase="'li'" [ngClass]="classes" [title]="title">
    {{ content }}
  </li>
</ng-container>
