import { Component, Input, OnInit } from '@angular/core';

type TypographyTag = 'p' | 'span' | 'div' | 'a' | 'li';
type Size =
  | 'xs'
  | 'xs-sm'
  | 'sm'
  | 'sm-md'
  | 'md'
  | 'md-lg'
  | 'lg'
  | 'lg-xl'
  | 'xl'
  | '2xl';
type TextAlign = 'left' | 'center' | 'right';
type TextTransform = 'uppercase' | 'lowercase' | 'capitalize';

@Component({
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss'],
})
export class TypographyComponent implements OnInit {
  @Input() tag: TypographyTag = 'p';
  @Input() size: Size = 'sm';
  @Input() type: 'ui-text' | 'ui-link' | 'ui-heading' = 'ui-text';
  @Input() strong: boolean = false;
  @Input() href: string | null = null;
  @Input() target: string | null = null;
  @Input() className: string = '';
  @Input() title: string = '';
  @Input() color: string | null = null;
  @Input() textAlign: TextAlign | null = null;
  @Input() textTransform: TextTransform | null = null;
  @Input() content!: string;
  classes: string[] = [];

  constructor() {}

  ngOnInit() {
    this.applyClasses();
  }

  private applyClasses() {
    this.classes = [this.type, 'size--' + this.size];

    if (this.strong) {
      this.classes.push('text--strong');
    }
    if (this.color) {
      this.classes.push('color--' + this.color);
    }
    if (this.textAlign) {
      this.classes.push('text--' + this.textAlign);
    }
    if (this.textTransform) {
      this.classes.push('text--' + this.textTransform);
    }
    if (this.className) {
      this.classes.push(this.className);
    }
  }
}
