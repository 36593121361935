import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { ManageHttpInterceptor } from './manage-rest-api.interceptor';
import { LoaderInterceptor } from './loader.interceptor';
import { AuthInterceptor } from './auth.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class InterceptorsModule {
  static forRoot(): ModuleWithProviders<InterceptorsModule> {
    return {
      ngModule: InterceptorsModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ManageHttpInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
      ],
    };
  }
}
