import { HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService implements OnDestroy {
  private pendingHTTPRequests$ = new Subject<void>();
  private requests: HttpRequest<unknown>[] = [];

  constructor(private loadingService: LoadingService) {}

  public cancelPendingRequests(): void {
    this.pendingHTTPRequests$.next();
  }

  public onCancelPendingRequests(): Observable<void> {
    return this.pendingHTTPRequests$.asObservable();
  }

  setRequests(request: HttpRequest<unknown>): void {
    this.requests.push(request);
  }

  getRequests(): HttpRequest<unknown>[] {
    return this.requests;
  }

  removeHttpRequest(req: HttpRequest<unknown>): void {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loadingService.setLoader(this.requests.length > 0);
  }

  ngOnDestroy(): void {
    // Cleanup logic if needed
    this.pendingHTTPRequests$.complete();
  }
}
