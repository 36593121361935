import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HamburgerMenuService {
  showSideMenu = true;
  constructor() {}

  openSideMenu() {
    this.showSideMenu = true;
  }

  closeSideMenu() {
    this.showSideMenu = false;
  }
}
