import { Component } from '@angular/core';
import {
  COPYRIGHT,
  VERSION_NUMBER,
} from 'src/utils/constants/global-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  versionNumber = VERSION_NUMBER;
  copyright = COPYRIGHT;
  constructor() {}
}
