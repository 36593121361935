import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpBackend,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {
  reqPath!: string;

  constructor(
    private httpClient: HttpClient,
    private handler: HttpBackend,
  ) {}

  getResource(key: string): void {
    this.reqPath = key;
  }

  getHttpClientInstance(isHandlerRequired: boolean = false): HttpClient {
    return isHandlerRequired ? new HttpClient(this.handler) : this.httpClient;
  }

  find<T>(key: string, isHandlerRequired: boolean = false): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).get<T>(this.reqPath);
  }

  findOne<T>(
    id: string | number,
    key: string,
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).get<T>(
      `${this.reqPath}/${id}`,
    );
  }

  findAll<T>(key: string, isHandlerRequired: boolean = false): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).get<T>(this.reqPath);
  }

  findUsingOptions<T>(
    key: string,
    options: { headers?: HttpHeaders; params?: HttpParams },
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.reqPath = key;
    return this.getHttpClientInstance(isHandlerRequired).get<T>(
      this.reqPath,
      options,
    );
  }

  login<T>(
    key: string,
    item: unknown,
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).post<T>(
      this.reqPath,
      item,
    );
  }

  save<T>(
    key: string,
    item: unknown,
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).post<T>(
      this.reqPath,
      item,
    );
  }

  saveUsingOptions<T extends HttpEvent<unknown>>(
    key: string,
    item: unknown,
    options: { headers?: HttpHeaders; params?: HttpParams },
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).post<T>(
      this.reqPath,
      item,
      options,
    );
  }

  update<T>(
    key: string,
    item: unknown,
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).put<T>(
      this.reqPath,
      item,
    );
  }

  updateWithoutItem<T>(
    key: string,
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).put<T>(
      this.reqPath,
      {},
    );
  }

  updateUsingOptions<T extends HttpEvent<unknown>>(
    key: string,
    item: unknown,
    options: { headers?: HttpHeaders; params?: HttpParams },
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).put<T>(
      this.reqPath,
      item,
      options,
    );
  }

  remove<T>(key: string, isHandlerRequired: boolean = false): Observable<T> {
    this.getResource(key);
    return this.getHttpClientInstance(isHandlerRequired).delete<T>(
      this.reqPath,
    );
  }

  removeUsingOptions<T>(
    key: string,
    item: unknown,
    headers: HttpHeaders | null = null,
    isHandlerRequired: boolean = false,
  ): Observable<T> {
    this.getResource(key);
    const options = { headers: headers || undefined, body: item || undefined };
    return this.getHttpClientInstance(isHandlerRequired).delete<T>(
      this.reqPath,
      options,
    );
  }
}
