<div class="footer-container">
  <app-typography
    tag="p"
    type="ui-text"
    size="xs-sm"
    [strong]="false"
    color="silver"
    textAlign="center"
    [content]="versionNumber"
  ></app-typography>
  <app-typography
    tag="p"
    class="footer-text"
    type="ui-text"
    size="xs-sm"
    [strong]="false"
    color="silver"
    textAlign="center"
    [content]="copyright"
  ></app-typography>
</div>
