import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { LoadingService } from '../services/loading/loading.service';
import { HelperService } from '../services/request-manager/request-manager.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<unknown>[] = [];

  constructor(
    private loaderService: LoadingService,
    private helperService: HelperService,
  ) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    this.helperService.setRequests(req);
    this.loaderService.setLoader(true);
    return next.handle(req).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          this.helperService.removeHttpRequest(req);
        }
      }),
      finalize(() => {
        this.helperService.removeHttpRequest(req);
      }),
    );
  }
}
