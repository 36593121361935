import { Component, OnDestroy, OnInit } from '@angular/core';
import { HamburgerMenuService } from '../shared/services/hamburger-menu/hamburger-menu.service';
import { AuthService } from '../core/services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  isUserExist!: boolean;
  subscriptions: Subscription[] = [];
  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
  constructor(
    public hamburgerMenuService: HamburgerMenuService,
    private authService: AuthService,
  ) {}
  ngOnInit() {
    const loginStatusSubscription = this.authService.isUserLoggedIn$.subscribe(
      loginStatus => (this.isUserExist = loginStatus),
    );
    this.subscriptions.push(loginStatusSubscription);
  }
}
