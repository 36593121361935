import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { HamburgerMenuService } from 'src/app/shared/services/hamburger-menu/hamburger-menu.service';
import { NavbarItems } from 'src/utils/interfaces/dashboard.interface';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  navbarList: NavbarItems[] = [];

  constructor(
    public hamburgerMenuService: HamburgerMenuService,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.authService.role$.subscribe(role => {
      this.navbarList = this.setNavbarItems(role);
    });
  }

  setNavbarItems(role: string) {
    let invoiceNavItem = 'Invoices';
    if (role === 'Viewer') {
      invoiceNavItem += ' (View Only)';
    }
    return [
      { navItem: 'Dashboard', navigationUrl: '/home/dashboard' },
      { navItem: invoiceNavItem, navigationUrl: '/home/invoices' },
    ];
  }
}
