import { Injectable } from '@angular/core';
import { LoginSummary } from 'src/utils/interfaces/api-responses.interface';
import { TokenPayload } from 'src/utils/interfaces/auth.interface';
import { RestApiService } from '../../http/rest-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { Params } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isUserLoggedInSubject = new BehaviorSubject<boolean>(false);
  public isUserLoggedIn$ = this.isUserLoggedInSubject.asObservable();
  private userNameSubject = new BehaviorSubject<string>('');
  public userName$ = this.userNameSubject.asObservable();
  private roleSubject = new BehaviorSubject<string>('');
  public role$ = this.roleSubject.asObservable();

  constructor(
    private restApiService: RestApiService,
    public jwtHelper: JwtHelperService,
  ) {
    this.checkInitialLoginStatus();
  }

  public login(payload: Params): Observable<LoginSummary> {
    return this.restApiService.save(`${environment.baseUrl}/login`, payload);
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    try {
      const isTokenExpired = this.jwtHelper.isTokenExpired(token);
      if (isTokenExpired) {
        this.isUserLoggedInSubject.next(false);
      }
      return !isTokenExpired;
    } catch (error) {
      this.isUserLoggedInSubject.next(false);
      return false;
    }
  }

  public setToken(token: string): void {
    localStorage.setItem('access_token', token);
    this.isUserLoggedInSubject.next(true);
    const tokenPayload = jwtDecode(token) as TokenPayload;
    this.userNameSubject.next(tokenPayload.userName);
    this.roleSubject.next(tokenPayload.role);
  }

  public getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  public getRole(): string {
    const token: string = this.getToken() as string;
    const tokenPayload: TokenPayload = jwtDecode(token);
    return tokenPayload.role;
  }

  public getUserName(): string {
    const token: string = this.getToken() as string;
    const tokenPayload: TokenPayload = jwtDecode(token);
    return tokenPayload.userName;
  }

  public getOuCode(): string {
    const token: string = this.getToken() as string;
    const tokenPayload: TokenPayload = jwtDecode(token);
    return tokenPayload.ouCode;
  }

  public clearToken() {
    localStorage.clear();
  }

  private checkInitialLoginStatus(): void {
    const token = this.getToken();
    if (token) {
      this.isUserLoggedInSubject.next(true);
      this.userNameSubject.next(this.getUserName());
      this.roleSubject.next(this.getRole());
    }
  }
  public handleTokenExpiration(): void {
    this.isUserLoggedInSubject.next(false);
  }
}
