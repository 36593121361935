<div class="align-navbar">
  <app-header class="header"></app-header>
  <div
    class="take-available-space"
    [ngClass]="{
      'invalid-login-page': !isUserExist
    }"
  >
    <app-navigation
      class="navigation-menu-container"
      *ngIf="hamburgerMenuService.showSideMenu"
    ></app-navigation>
    <section
      [ngClass]="{
        'global-content-section': hamburgerMenuService.showSideMenu,
        'w-100': !hamburgerMenuService.showSideMenu
      }"
    >
      <router-outlet></router-outlet>
    </section>
  </div>
  <app-footer class="footer"></app-footer>
</div>
