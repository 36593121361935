import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading/loading.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  loaderSubscription!: Subscription;
  isLoading$!: boolean;
  constructor(private loaderService: LoadingService) {}

  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderSubscription = this.loaderService
      .getLoader()
      .subscribe((res: boolean) => {
        this.isLoading$ = res;
      });
  }
}
