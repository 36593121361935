import {
  ExportData,
  InvoiceTypeCode,
  SearchPayloadToSearchNames,
} from '../interfaces/invoice-list.interface';
import { PaginationInfo } from '../interfaces/paginationInfo.interface';

export const NAVBAR_TITLE = 'TGM E-Invoice Custom Application';

export const WELCOME_MESSAGE = 'Welcome to E-Invoice';

export const VERSION_NUMBER = 'TMS UI Template V1.2';

export const COPYRIGHT = 'Copyright © 2024 TORAY MALAYSIA SYSTEMS SOLUTION';

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const COLORS = {
  $02A053: '#02A053',
  $B1003C: '#B1003C',
  $024096: '#024096',
  $535759: '#535759',
  $FF850B: '#FF850B',
  $000000: '#000000',
};

export const STATUS_ICONS = {
  READY_FOR_REVIEW: '/assets/invoice-received.svg',
  APPROVED: '/assets/invoice-approved.svg',
  REJECTED: '/assets/invoice-rejected.svg',
  SUBMITTED: '/assets/invoice-submitted.svg',
  VALIDATED: '/assets/invoice-valid.svg',
  INVALID: '/assets/invoice-invalid.svg',
  CANCELLED: '/assets/invoice-rejected.svg',
};

export const INVOICE_STASTISTICS_CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      max: 200,
      title: {
        display: true,
        text: 'Number Of Invoices',
        font: {
          size: 14,
          weight: 700,
        },
        color: '#707070',
      },
    },
    x: {
      title: {
        display: true,
        text: 'Months',
        font: {
          size: 14,
          weight: 700,
        },
        color: '#707070',
        padding: { top: 25 },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const STATISTICS_CHART_CONFIG = {
  BORDER_RADIUS: 10,
  BORDER_WIDTH: 1,
};

export const pageNums = [10, 25, 50, 100, -1];

export const DOCUMENT_STATUSES = {
  READY_FOR_REVIEW: 'ReadyForReview',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  SUBMITTED: 'Submitted',
  VALIDATED: 'Validated',
  INVALID: 'Invalid',
  CANCELLED: 'Cancelled',
};

export const FORMATTED_READY_FOR_REVIEW = 'Ready For Review';

export const TRANSACTION_TYPES = {
  AR_SINGLE_E_INVOICE: 'AR SINGLE E-INVOICE',
  AR_CONSOLIDATED_E_INVOICE: 'AR CONSOLIDATED E-INVOICE',
  AP_SELF_BILLED: 'AP SELF-BILLED',
  SUPPLIER_VALIDATED_E_INVOICE: 'SUPPLIER VALIDATED E-INVOICE',
};

export const HEADER_CONTENT = 'TORAY MALAYSIA SYSTEM SOLUTION';

export const INVOICE_TABLE_ITEM_HEADERS = [
  'Line no',
  'Description of Product or Service',
  'Qty',
  'UOM',
  'Currency',
  'Unit Price',
  'Tax Amount',
  'Total Excluding Tax',
];

export const NUM_TYPE_ITEM_HEADERS = [
  'Qty',
  'Unit Price',
  'Tax Amount',
  'Total Excluding Tax',
];

export const THRESHOLD_TIME_IN_HOURS = 72;

export const INVOICE_TYPE_CODES: Record<InvoiceTypeCode, string> = {
  '01': 'Invoice',
  '02': 'Credit Note',
  '03': 'Debit Note',
  '04': 'Refund Note',
  '11': 'Self-billed Invoice',
  '12': 'Self-billed Credit Note',
  '13': 'Self-billed Debit Note',
  '14': 'Self-billed Refund Note',
};

export const EXCEL_CONFIG = {
  EXCEL_TYPE:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  EXCEL_EXTENSION: '.xlsx',
};

export const NOTIFICATION_CONFIGURATIONS = {
  TIME_OUT: 3000,
  POSITION_CLASS: 'toast-top-center',
  PREVENT_DUPLICATES: true,
};

export const TOASTR_SUCCESS_MESSAGES = {
  APPROVED: 'Approved successfully',
  REJECTED: 'Rejected successfully',
  CANCELLED: 'Cancelled successfully',
  EXPORT: 'Exported successfully',
  MORE_THAN_ONE_CANCEL: 'Cancellation of Multiple Invoices is not allowed',
};

export const TOASTR_ERROR_MESSAGES = {
  GENERIC_HTTP_REQUEST:
    'Oops. Unable to process request.Please try after sometime!!',
  NO_RECORDS_FOUND: 'No document(s) found',
  UNAUTHORIZED: 'Unauthorized',
  UNPROCESSABLE_ENTITY: 'Unprocessable Entity',
  UNKNOWN_ERROR: 'An unknown error occurred',
  NO_DATA_FOUND: 'No data found',
  MORE_THAN_3_MONTHS: 'Date range should be with in 3 months only',
};

export const FIELD_LABEL_TO_KEY_MAP = {
  invoiceType: 'eInvoiceTypes',
  currencyCode: 'currencyCodes',
};

export const SEARCH_PAYLOAD_TO_SEARCH_NAMES: SearchPayloadToSearchNames = {
  transactionDate: 'Transaction Date',
  glDate: 'GL Date',
  eInvoiceTypes: 'Invoice Type',
  entity: 'Entity',
  uuid: 'UUID',
  currencyCodes: 'Currency Code',
  status: 'Invoice Status',
  partyName: 'Buyer/Supplier Name',
  partyTin: 'Buyer/Supplier TIN',
  validatedAt: 'E-Invoice Date',
};

export const INITIAL_PAGINATION_INFO: PaginationInfo = {
  disablePrev: false,
  disableNext: false,
  totalPages: 0,
  pageSize: 10,
  totalItems: 0,
};
export const OPERATING_UNITS = [
  'ALL',
  'PENFABRIC SDN BHD',
  'TML SDN. BHD',
  'PENFIBRE SDN - FIBRE DIVISION',
  'PENFIBRE SDN - FILM DIVISION',
  'Toray Plastics (Malaysia)Sdn Bhd',
];

export const TEXT = {
  INVOICES: 'Invoices',
};

export const STATUS_CARD_TITLES = [
  'Ready For Review',
  'Approved Invoices',
  'Rejected Invoices',
  'Submitted Invoices',
  'Valid Invoices',
  'Invalid Invoices',
  'Cancelled Invoices',
];

export const DEFAULT_VALUES = {
  TIMESTAMP: ' 00:00:00',
  ALL: 'All',
};

export const STATUS_CARD_HEADINGS = {
  SINGLE: 'AR E-Invoice',
  SELF_BILLED: 'Self Billed',
  CONSOLODATED: 'AR Consolidated E-Invoice',
  SUPPLIER_VALIDATED: 'Supplier Validated E-Invoice',
};

export const FAILED = 'Failed';

export const SEARCH_FIELD_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
  },
};

export const DATEADAPTER_REGION = 'en-GB';
export const VERSION_INDICATOR = ' - V';
export const EXPORTDATA_UBL_MAPPING_CONSTANTS = {
  schemeIDs: ['BRN', 'SST', 'TTX', 'TIN'],
  currencyId: 'MYR',
  documentType: ['CustomsImportForm', 'K2', 'FreeTradeAgreement'],
  schemeAgencyName: 'CertEx',
  categoryID: 'E',
  scheme: 'OTH',
  taxSchemeID: 'UN/ECE 5153',
  documentReferenceId: 'FTA',
  schemeAgencyId: '6',
  listId: 'PTC',
  classificationCode: 'CLASS',
  registrationNumberschemeIDS: ['BRN', 'NRIC', 'PASSPORT', 'ARMY'],
};

export const LookUpTypes = {
  E_INVOICE_TYPES: 'EInvoiceTypes',
  INVOICE_STATUS: 'INVOICESTATUS',
  OU_ENTITY_TENANT: 'OU_ENTITY_TENANT',
  TRANSACTION_TYPES: 'TransactionTypes',
};

export const INVOICE_STATUS_KEY = 'status';

export const SUB_SET_DROPDOWN_FIELDS = ['operatingUnit', 'transactionType'];

export const DOWNLOADED_EXCEL_FILE_NAMES = {
  auditTrail: 'AuditTrail',
  invoices: 'Invoices',
};

export const OU_PENFIBRE_PARENT_COMPANY = 'PENFIBRE SDN BHD';

export const EXPORT_TO_EXCEL_NUMERIC_FIELDS: (keyof ExportData)[] = [
  'Currency_Exchange_Rate',
  'Unit_Price',
  'Tax_Rate',
  'Tax_Amount',
  'Amount_Exempted_From_Tax',
  'Subtotal',
  'Total_Excluding_Tax',
  'Total_Including_Tax',
  'Total_Net_Amount',
  'Total_Payable_Amount',
  'Total_Payable_Amount_Converted_Currency',
  'Rounding_Amount',
  'Total_Taxable_Amount_Per_Tax_Type',
  'Discount_Rate',
  'Discount_Amount',
  'Fee_or_ChargeRate',
  'Fee_or_ChargeAmount',
  'Prepayment_Amount',
];

export const EXCEL_NUMBER_COLUMN_REFERENCES = [
  'AE',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AZ',
  'BA',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
];
