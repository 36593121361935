<aside class="navigation-bar">
  <div>
    <div class="vertical-center hamburger-container">
      <img
        class="cursor-pointer mr-5"
        src="/assets/menu-icon.svg"
        (click)="this.hamburgerMenuService.closeSideMenu()"
      />
      <app-typography
        content="Menu"
        title=""
        type="ui-heading"
        size="md"
        color="gray-500"
      >
      </app-typography>
    </div>
    <div>
      <ul>
        <li
          *ngFor="let menu of navbarList"
          routerLinkActive="active-background"
          class="nav-item"
        >
          <a [routerLink]="menu.navigationUrl" class="nav-link">
            <app-typography
              [content]="menu.navItem"
              type="ui-text"
              color="gray-300"
              title=""
              size="md-lg"
              tag="span"
            ></app-typography>
          </a>
        </li>
      </ul>
    </div>
  </div>
</aside>
