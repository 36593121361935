import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { InterceptorsModule } from './core/interceptors/interceptors.module';
import { ToastrModule } from 'ngx-toastr';
import { NOTIFICATION_CONFIGURATIONS } from 'src/utils/constants/global-constants';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    SharedModule,
    LayoutModule,
    NoopAnimationsModule,
    OverlayModule,
    ToastrModule.forRoot({
      timeOut: NOTIFICATION_CONFIGURATIONS.TIME_OUT,
      positionClass: NOTIFICATION_CONFIGURATIONS.POSITION_CLASS,
      preventDuplicates: NOTIFICATION_CONFIGURATIONS.PREVENT_DUPLICATES,
    }),
    InterceptorsModule.forRoot(),
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
