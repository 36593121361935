import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loaderSubject = new BehaviorSubject<boolean>(false);
  loaderState = this.loaderSubject.asObservable();

  constructor() {}

  getLoader(): Observable<boolean> {
    return this.loaderState;
  }

  setLoader(isLoading: boolean): void {
    this.loaderSubject.next(isLoading);
  }
}
