import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(
    value: number,
    locale: string = 'en-US',
    options?: Intl.NumberFormatOptions,
  ): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    const formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(value);
  }
}
