// export const endPoints = {
  //   ip: '10.211.2.17',
  //   port: ':3354',
  //   contextPath: '/api/1.1',
  // };
  
  // export const environment = {
  //   production: false,
  //   baseUrl: `http://${endPoints.ip}${endPoints.port}${endPoints.contextPath}`,
  // };
  
  export const endPoints = {
    ip: 'einv-tgm-pfr.com',
    port: ':3350',
    contextPath: '/api/1.1',
   };
   export const environment = {
    production: false,
    baseUrl: `https://${endPoints.ip}${endPoints.contextPath}`,
   };
  