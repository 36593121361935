import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TOASTR_ERROR_MESSAGES } from 'src/utils/constants/global-constants';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let errorMessage = '';
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              if (error.error.details.errorDescription) {
                if (typeof error.error.details.errorDescription === 'string') {
                  errorMessage = error.error.details.errorDescription;
                } else {
                  errorMessage =
                    error.error.details.errorDescription[0].loc[1] + ' missing';
                }
              } else {
                errorMessage = TOASTR_ERROR_MESSAGES.GENERIC_HTTP_REQUEST;
              }
              break;
            case 401:
              this.authService.handleTokenExpiration();
              errorMessage = TOASTR_ERROR_MESSAGES.UNAUTHORIZED;
              break;
            case 422:
              errorMessage = TOASTR_ERROR_MESSAGES.UNPROCESSABLE_ENTITY;
              break;
            case 500:
              errorMessage = TOASTR_ERROR_MESSAGES.GENERIC_HTTP_REQUEST;
              break;
            default:
              errorMessage = TOASTR_ERROR_MESSAGES.GENERIC_HTTP_REQUEST;
              break;
          }
          this.toastrService.error(errorMessage);
          console.error(errorMessage); // Log the error message or handle it as required
        }
        return throwError(() => ({ message: errorMessage }));
      }),
    );
  }
}
